

















































































































































































































































































































































































































































































































.answerPage {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
}
.mt20 {
  margin-top: 20px;
}
.mr20 {
  margin-right: 20px;
}
.examInfo {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #fafafa;
  font-size: 16px;
  color: #333;
}
.content {
  flex: 1;
  display: flex;
  .content_row {
    margin-top: 20px;
    width: 1180px;
    display: flex;
    flex: 1;
    justify-content: center;
    .card_box {
      background: #fafafa;
      border-radius: 4px;
      padding: 20px 0;
      position: relative;
      text-align: left;
      &.w230 {
        width: 230px;
      }
      &.w630 {
        width: 630px;
      }
      &.w280 {
        width: 280px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 15px;
        background: #2878ff;
        top: 20px;
        left: 0;
      }
      &.noflag {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &:before {
          background: transparent;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      h3 {
        margin-left: 20px;
        line-height: 18px;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }
  }
  .timeRemainingTitle {
    color: #ff6030;
    font-size: 16px;
    margin-top: 2px;
  }
  .timeRemaining {
    margin-top: 8px;
    color: #ff6030;
    font-size: 24px;
    font-weight: bold;
  }
  .timeRemainingBtn {
    margin-top: 20px;
    .el-button {
      width: 105px;
    }
    .el-button--primary.is-plain {
      background-color: #e8f3ff;
      border-color: #e8f3ff;
      color: #2878ff;
    }
  }
  .studentInfo {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .infoText {
      margin-left: 20px;
      font-size: 14px;
      color: #333;
      & > div {
        line-height: 20px;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      span {
        color: #666;
      }
    }
  }
  .answerCards {
    padding-top: 8px;
    margin: 0 20px;
    .answerCardsItem {
      margin-bottom: 10px;
      .itemTitle {
        font-size: 14px;
        color: #333;
        margin-bottom: 11px;
      }
      .btnList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .btnItem {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #f1f1f1;
          background-color: #f1f1f1;
          margin-bottom: 10px;
          margin-right: 10px;
          color: #333;
          cursor: pointer;
          position: relative;
          &:nth-child(5n) {
            margin-right: 0;
          }
          &.haveRead {
            &::before {
              content: '';
              position: absolute;
              right: 3px;
              bottom: 3px;
              width: 10px;
              height: 10px;
              background: url('../../../assets/haveRead1.png') no-repeat;
              background-size: 100% 100%;
            }
          }
          &.correct {
            border: 1px solid #11aa66;
            background: rgba(17, 170, 102, 0.3);
            color: #11aa66;
          }
          &.partOfRight {
            border: 1px solid #84d1af;
            background: transparent;
            color: #11aa66;
          }
          &.error {
            border: 1px solid #fcbfb5;
            background: rgba(255, 74, 46, 0.05);
            color: #ff4a2e;
          }
          &.current {
            border: 1px solid #2878ff;
            background: #f4f6f9;
            color: #2878ff;
          }
        }
      }
    }
  }
  .answerCardsBtn {
    background: #f1f1f1;
    padding: 15px 0;
    border-radius: 0 0 4px 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    div {
      padding-left: 20px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 50%;
        width: 16px;
        height: 16px;
        margin-top: -7px;
        box-sizing: border-box;
        border-radius: 2px;
      }
    }
    .noAnswer::before {
      border: 1px solid #666666;
    }
    .correct::before {
      border: 1px solid #11aa66;
      background: rgba(17, 170, 102, 0.3);
    }
    .partOfRight::before {
      border: 1px solid #84d1af;
    }
    .error::before {
      border: 1px solid #ff4a2e;
      background: rgba(255, 74, 46, 0.05);
    }
    .didNotRead::before {
      border: 1px solid #666666;
    }
    .haveRead::before {
      background: url('../../../assets/haveRead2.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .listItem {
    padding: 16px 0;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:last-of-type {
      border-bottom: none;
    }
    .item1 {
      font-size: 14px;
      color: #666666;
      margin-bottom: 8px;
    }
    .item2 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
  }
  .btnWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    height: 30%;
    min-height: 200px;
  }
  //答题区域

  .questionType {
    margin: 0 20px;
    word-break: break-all;
    .questionTitle,
    .parentQuestionTitle {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .questionTypeTips {
        font-size: 12px;
        color: #2878ff;
        padding: 3px 8px;
        background: #f4f6f9;
        border: 1px solid #2878ff;
        border-radius: 4px;
        margin-right: 8px;
      }
      .questionTitleText {
        font-size: 16px;
        color: #333;
      }
    }
    .parentQuestionTitle {
      margin-bottom: 20px;
    }
    .questionOptions {
      margin-left: 50px;
      .optionItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 8px;
        .optionNum {
          display: flex;
          width: 22px;
          height: 22px;
          min-width: 22px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #f1f1f1;
          font-size: 12px;
          color: #666;
          margin-right: 4px;
        }
        .optionInfo {
          padding: 8px 0;
          font-size: 14px;
          color: #666;
          line-height: 1.4;
        }
        &.active {
          .optionNum {
            background-color: #11aa66;
            color: #fff;
          }
          .optionInfo {
            color: #11aa66;
          }
        }
      }
    }
    .questionCompletions {
      margin: 0 50px;
      .completionItem {
        margin: 12px 0;
        border: 1px solid #f1f1f1;
        .completionTitle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 23px;
          padding: 0 8px;
          background-color: #f4f6f9;
          border-radius: 2px 2px 0px 0px;
          font-size: 12px;
          color: #2878ff;
          &.success {
            color: #11aa66;
            background-color: #edf5f2;
          }
        }
        .completionValue {
          padding: 10px 0 0 8px;
          .answer {
            border: 0;
            font-size: 14px;
            min-height: 5em;
            color: #333;
            background-color: transparent;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .hasParent {
    .questionTitle {
      margin-left: 50px;
    }
    .questionOptions,
    .questionCompletions {
      margin-left: 100px;
    }
  }
  .el-input-number--mini {
    width: 70px;
    /deep/ .el-input__inner {
      border: 1px solid #2878ff;
      border-left: none;
      border-radius: 0 4px 4px 0;
      color: #2878ff;
      font-weight: bold;
    }
  }
  .pfst {
    background: #2878ff;
    border-radius: 4px 0 0 4px;
    color: #fff;
    padding: 4px;
  }
  .pfrst {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999999;
    margin-left: 4px;
  }
  .result {
    .correct {
      position: relative;
      color: #11aa66;
    }
    .partOfRight {
      position: relative;
      color: #11aa66;
    }
    .error {
      position: relative;
      color: #ff4a2e;
    }
    .correct + .symbol {
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/sucess1.png') no-repeat;
      background-size: 100% 100%;
      color: #11aa66;
    }
    .partOfRight + .symbol {
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/sucess2.png') no-repeat;
      background-size: 100% 100%;
      color: #11aa66;
    }
    .error + .symbol {
      content: '';
      display: inline-block;
      margin-left: 8px;
      width: 16px;
      height: 16px;
      background: url('../../../assets/error1.png') no-repeat;
      background-size: 100% 100%;
      color: #ff4a2e;
    }
    .lf {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px 0 0 4px;
    }
    .rf {
      height: 26px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 4px 4px 0;
    }
    .lf.sucess {
      background: #11aa66;
    }
    .rf.sucess {
      border: 1px solid #11aa66;
      color: #11aa66;
    }
    .lf.err {
      background: #ff4a2e;
    }
    .rf.err {
      border: 1px solid #ff4a2e;
      color: #ff4a2e;
    }
  }
  .record {
    color: #999999;
    line-height: 2em;
    padding: 20px 20px 50px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .df > div {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
