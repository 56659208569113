.answerPage {
  flex: 1;
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
}
.mt20 {
  margin-top: 20px;
}
.mr20 {
  margin-right: 20px;
}
.examInfo {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #fafafa;
  font-size: 16px;
  color: #333;
}
.content {
  flex: 1;
  display: flex;
}
.content .content_row {
  margin-top: 20px;
  width: 1180px;
  display: flex;
  flex: 1;
  justify-content: center;
}
.content .content_row .card_box {
  background: #fafafa;
  border-radius: 4px;
  padding: 20px 0;
  position: relative;
  text-align: left;
}
.content .content_row .card_box.w230 {
  width: 230px;
}
.content .content_row .card_box.w630 {
  width: 630px;
}
.content .content_row .card_box.w280 {
  width: 280px;
}
.content .content_row .card_box:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 15px;
  background: #2878ff;
  top: 20px;
  left: 0;
}
.content .content_row .card_box.noflag {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.content .content_row .card_box.noflag:before {
  background: transparent;
}
.content .content_row .card_box:last-child {
  margin-right: 0;
}
.content .content_row .card_box h3 {
  margin-left: 20px;
  line-height: 18px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 12px;
}
.content .timeRemainingTitle {
  color: #ff6030;
  font-size: 16px;
  margin-top: 2px;
}
.content .timeRemaining {
  margin-top: 8px;
  color: #ff6030;
  font-size: 24px;
  font-weight: bold;
}
.content .timeRemainingBtn {
  margin-top: 20px;
}
.content .timeRemainingBtn .el-button {
  width: 105px;
}
.content .timeRemainingBtn .el-button--primary.is-plain {
  background-color: #e8f3ff;
  border-color: #e8f3ff;
  color: #2878ff;
}
.content .studentInfo {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.content .studentInfo .infoText {
  margin-left: 20px;
  font-size: 14px;
  color: #333;
}
.content .studentInfo .infoText > div {
  line-height: 20px;
  margin-bottom: 8px;
}
.content .studentInfo .infoText > div:last-child {
  margin-bottom: 0;
}
.content .studentInfo .infoText span {
  color: #666;
}
.content .answerCards {
  padding-top: 8px;
  margin: 0 20px;
}
.content .answerCards .answerCardsItem {
  margin-bottom: 10px;
}
.content .answerCards .answerCardsItem .itemTitle {
  font-size: 14px;
  color: #333;
  margin-bottom: 11px;
}
.content .answerCards .answerCardsItem .btnList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.content .answerCards .answerCardsItem .btnList .btnItem {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #333;
  cursor: pointer;
  position: relative;
}
.content .answerCards .answerCardsItem .btnList .btnItem:nth-child(5n) {
  margin-right: 0;
}
.content .answerCards .answerCardsItem .btnList .btnItem.haveRead::before {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 10px;
  height: 10px;
  background: url('../../../assets/haveRead1.png') no-repeat;
  background-size: 100% 100%;
}
.content .answerCards .answerCardsItem .btnList .btnItem.correct {
  border: 1px solid #11aa66;
  background: rgba(17, 170, 102, 0.3);
  color: #11aa66;
}
.content .answerCards .answerCardsItem .btnList .btnItem.partOfRight {
  border: 1px solid #84d1af;
  background: transparent;
  color: #11aa66;
}
.content .answerCards .answerCardsItem .btnList .btnItem.error {
  border: 1px solid #fcbfb5;
  background: rgba(255, 74, 46, 0.05);
  color: #ff4a2e;
}
.content .answerCards .answerCardsItem .btnList .btnItem.current {
  border: 1px solid #2878ff;
  background: #f4f6f9;
  color: #2878ff;
}
.content .answerCardsBtn {
  background: #f1f1f1;
  padding: 15px 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.content .answerCardsBtn div {
  padding-left: 20px;
  position: relative;
}
.content .answerCardsBtn div::before {
  content: '';
  position: absolute;
  left: -4px;
  top: 50%;
  width: 16px;
  height: 16px;
  margin-top: -7px;
  box-sizing: border-box;
  border-radius: 2px;
}
.content .answerCardsBtn .noAnswer::before {
  border: 1px solid #666666;
}
.content .answerCardsBtn .correct::before {
  border: 1px solid #11aa66;
  background: rgba(17, 170, 102, 0.3);
}
.content .answerCardsBtn .partOfRight::before {
  border: 1px solid #84d1af;
}
.content .answerCardsBtn .error::before {
  border: 1px solid #ff4a2e;
  background: rgba(255, 74, 46, 0.05);
}
.content .answerCardsBtn .didNotRead::before {
  border: 1px solid #666666;
}
.content .answerCardsBtn .haveRead::before {
  background: url('../../../assets/haveRead2.png') no-repeat;
  background-size: 100% 100%;
}
.content .listItem {
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content .listItem:last-of-type {
  border-bottom: none;
}
.content .listItem .item1 {
  font-size: 14px;
  color: #666666;
  margin-bottom: 8px;
}
.content .listItem .item2 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.content .btnWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  height: 30%;
  min-height: 200px;
}
.content .questionType {
  margin: 0 20px;
  word-break: break-all;
}
.content .questionType .questionTitle,
.content .questionType .parentQuestionTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.content .questionType .questionTitle .questionTypeTips,
.content .questionType .parentQuestionTitle .questionTypeTips {
  font-size: 12px;
  color: #2878ff;
  padding: 3px 8px;
  background: #f4f6f9;
  border: 1px solid #2878ff;
  border-radius: 4px;
  margin-right: 8px;
}
.content .questionType .questionTitle .questionTitleText,
.content .questionType .parentQuestionTitle .questionTitleText {
  font-size: 16px;
  color: #333;
}
.content .questionType .parentQuestionTitle {
  margin-bottom: 20px;
}
.content .questionType .questionOptions {
  margin-left: 50px;
}
.content .questionType .questionOptions .optionItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8px;
}
.content .questionType .questionOptions .optionItem .optionNum {
  display: flex;
  width: 22px;
  height: 22px;
  min-width: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f1f1f1;
  font-size: 12px;
  color: #666;
  margin-right: 4px;
}
.content .questionType .questionOptions .optionItem .optionInfo {
  padding: 8px 0;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}
.content .questionType .questionOptions .optionItem.active .optionNum {
  background-color: #11aa66;
  color: #fff;
}
.content .questionType .questionOptions .optionItem.active .optionInfo {
  color: #11aa66;
}
.content .questionType .questionCompletions {
  margin: 0 50px;
}
.content .questionType .questionCompletions .completionItem {
  margin: 12px 0;
  border: 1px solid #f1f1f1;
}
.content .questionType .questionCompletions .completionItem .completionTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 23px;
  padding: 0 8px;
  background-color: #f4f6f9;
  border-radius: 2px 2px 0px 0px;
  font-size: 12px;
  color: #2878ff;
}
.content .questionType .questionCompletions .completionItem .completionTitle.success {
  color: #11aa66;
  background-color: #edf5f2;
}
.content .questionType .questionCompletions .completionItem .completionValue {
  padding: 10px 0 0 8px;
}
.content .questionType .questionCompletions .completionItem .completionValue .answer {
  border: 0;
  font-size: 14px;
  min-height: 5em;
  color: #333;
  background-color: transparent;
  word-wrap: break-word;
}
.content .hasParent .questionTitle {
  margin-left: 50px;
}
.content .hasParent .questionOptions,
.content .hasParent .questionCompletions {
  margin-left: 100px;
}
.content .el-input-number--mini {
  width: 70px;
}
.content .el-input-number--mini /deep/ .el-input__inner {
  border: 1px solid #2878ff;
  border-left: none;
  border-radius: 0 4px 4px 0;
  color: #2878ff;
  font-weight: bold;
}
.content .pfst {
  background: #2878ff;
  border-radius: 4px 0 0 4px;
  color: #fff;
  padding: 4px;
}
.content .pfrst {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
  margin-left: 4px;
}
.content .result .correct {
  position: relative;
  color: #11aa66;
}
.content .result .partOfRight {
  position: relative;
  color: #11aa66;
}
.content .result .error {
  position: relative;
  color: #ff4a2e;
}
.content .result .correct + .symbol {
  display: inline-block;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  background: url('../../../assets/sucess1.png') no-repeat;
  background-size: 100% 100%;
  color: #11aa66;
}
.content .result .partOfRight + .symbol {
  display: inline-block;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  background: url('../../../assets/sucess2.png') no-repeat;
  background-size: 100% 100%;
  color: #11aa66;
}
.content .result .error + .symbol {
  content: '';
  display: inline-block;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  background: url('../../../assets/error1.png') no-repeat;
  background-size: 100% 100%;
  color: #ff4a2e;
}
.content .result .lf {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
}
.content .result .rf {
  height: 26px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
}
.content .result .lf.sucess {
  background: #11aa66;
}
.content .result .rf.sucess {
  border: 1px solid #11aa66;
  color: #11aa66;
}
.content .result .lf.err {
  background: #ff4a2e;
}
.content .result .rf.err {
  border: 1px solid #ff4a2e;
  color: #ff4a2e;
}
.content .record {
  color: #999999;
  line-height: 2em;
  padding: 20px 20px 50px;
}
.content .record > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .record > div .df > div {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
